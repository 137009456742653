<template>
  <div>
    <div class="flex flex-col md:flex-row justify-center justify-items-center items-center mb-6">
      <Title :title="$route.meta.title || 'Statistics'" size="large" />

      <div class="flex flex-col md:flex-row gap-4 justify-center justify-items-center items-center md:ml-auto">
        <Button
          :class="currentTab === 1 ? 'text-primary-default' : ''"
          kind="link"
          :grow="windowWidth <= 768"
          label="Statistics"
          url="/admin"
        />

        <Button
          :class="currentTab === 2 ? 'text-primary-default' : ''"
          kind="link"
          :grow="windowWidth <= 768"
          label="Campaigns"
          url="/admin/campaigns"
        />

        <Button
          :class="currentTab === 3 ? 'text-primary-default' : ''"
          kind="link"
          :grow="windowWidth <= 768"
          label="Demographics"
          url="/admin/demographics"
        />

        <Button
          :class="currentTab === 4 ? 'text-primary-default' : ''"
          kind="link"
          :grow="windowWidth <= 768"
          label="Creator pages"
          url="/admin/creator-pages"
        />

        <Button
          :class="currentTab === 4 ? 'text-primary-default' : ''"
          kind="link"
          :grow="windowWidth <= 768"
          label="Earnings"
          url="/admin/earnings"
        />

        <Button
          :class="currentTab === 5 ? 'text-primary-default' : ''"
          kind="link"
          :grow="windowWidth <= 768"
          label="Discover"
          url="/admin/discover"
        />

        <Dropdown
          button-text="Export data"
          :items="[
            {
              label: 'Weekly views',
              id: 'weekly-views',
            },
            {
              label: 'Monthly views',
              id: 'monthly-views',
            },
            {
              label: 'User data',
              id: 'user-data',
            },
            {
              label: 'Wallets',
              id: 'content-creator-wallets',
            },
          ]"
          :is-show-more="false"
          @change="exportData"
        />
      </div>
    </div>

    <router-view />
  </div>
</template>

<script>
import axios from "axios";
import { Button, Title } from "cavea-design-system";
import log from "@/lib/log";
import jsonToCsv from "@/lib/helpers/jsonToCsv";

export default {
  name: "Admin",

  metaInfo: {
    title: "User Statistics",
  },

  components: {
    Button,
    Title,
    Dropdown: () => import("@/components/Dropdown"),
  },

  data() {
    return {
      currentTab: 1,
      windowWidth: 1000,
    };
  },

  methods: {
    exportData(e) {
      log({ fn: "exportData", e });

      switch (e?.item?.id) {
        case "monthly-views":
          this.exportMonthViewsCSV();
          break;
        case "weekly-views":
          this.exportWeeklyViewsCSV();
          break;
        case "user-data":
          this.exportUserDataCSV();
          break;
        case "content-creator-wallets":
          this.exportContentCreatorWalletCSV();
          break;
        default:
          break;
      }
    },

    async exportMonthViewsCSV() {
      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/admin/users/monthly`;

      const data = await axios
        .get(URL)
        .then((result) => result?.data?.monthlyData)
        .catch((error) => {
          console.error(error);
        });

      jsonToCsv(data, `netwrk_monthly_views_export_${new Date().toISOString().split("T")[0]}.csv`);
    },

    async exportWeeklyViewsCSV() {
      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/admin/users/weekly`;

      const data = await axios
        .get(URL)
        .then((result) => result?.data?.weeklyData)
        .catch((error) => {
          console.error(error);
        });

      jsonToCsv(data, `netwrk_weekly_views_export_${new Date().toISOString().split("T")[0]}.csv`);
    },

    async exportUserDataCSV() {
      const HOST = process.env.VUE_APP_API_URL;
      const URL = `${HOST}/admin/users/csv`;

      const data = await axios
        .get(URL)
        .then((result) => result?.data?.data)
        .catch((error) => {
          console.error("exportCSV error", error);
        });

      jsonToCsv(data, `netwrk_users_export_${new Date().toISOString().split("T")[0]}.csv`);
    },

    async exportContentCreatorWalletCSV() {
      const data = await axios
        .get(`${process.env.VUE_APP_API_URL}/admin/content-creators/wallets`)
        .then((res) => res?.data?.result ?? [])
        .catch(() => []);

      jsonToCsv(data, `netwrk_wallet_export_${new Date().toISOString().split("T")[0]}.csv`);
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/helpers";
</style>
