import downloadFile from "./downloadFile";

export default function jsonToCsv(json, filename = false) {
  if (!Array.isArray(json)) {
    throw "input must be formatted as an array";
  }

  const fields = Object.keys(json[0]);
  const rows = [];
  rows.push(fields.join(","));
  for (const line of json) {
    const sb = [];
    for (const field of fields) {
      sb.push(line[field]);
    }
    rows.push(sb.join(","));
  }

  // download file
  if (filename) {
    downloadFile(filename, ["\uFEFF" + rows.join("\n")]);
  }
  return rows.join("\n");
}
