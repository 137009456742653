var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex flex-col md:flex-row justify-center justify-items-center items-center mb-6"},[_c('Title',{attrs:{"title":_vm.$route.meta.title || 'Statistics',"size":"large"}}),_c('div',{staticClass:"flex flex-col md:flex-row gap-4 justify-center justify-items-center items-center md:ml-auto"},[_c('Button',{class:_vm.currentTab === 1 ? 'text-primary-default' : '',attrs:{"kind":"link","grow":_vm.windowWidth <= 768,"label":"Statistics","url":"/admin"}}),_c('Button',{class:_vm.currentTab === 2 ? 'text-primary-default' : '',attrs:{"kind":"link","grow":_vm.windowWidth <= 768,"label":"Campaigns","url":"/admin/campaigns"}}),_c('Button',{class:_vm.currentTab === 3 ? 'text-primary-default' : '',attrs:{"kind":"link","grow":_vm.windowWidth <= 768,"label":"Demographics","url":"/admin/demographics"}}),_c('Button',{class:_vm.currentTab === 4 ? 'text-primary-default' : '',attrs:{"kind":"link","grow":_vm.windowWidth <= 768,"label":"Creator pages","url":"/admin/creator-pages"}}),_c('Button',{class:_vm.currentTab === 4 ? 'text-primary-default' : '',attrs:{"kind":"link","grow":_vm.windowWidth <= 768,"label":"Earnings","url":"/admin/earnings"}}),_c('Button',{class:_vm.currentTab === 5 ? 'text-primary-default' : '',attrs:{"kind":"link","grow":_vm.windowWidth <= 768,"label":"Discover","url":"/admin/discover"}}),_c('Dropdown',{attrs:{"button-text":"Export data","items":[
          {
            label: 'Weekly views',
            id: 'weekly-views',
          },
          {
            label: 'Monthly views',
            id: 'monthly-views',
          },
          {
            label: 'User data',
            id: 'user-data',
          },
          {
            label: 'Wallets',
            id: 'content-creator-wallets',
          } ],"is-show-more":false},on:{"change":_vm.exportData}})],1)],1),_c('router-view')],1)}
var staticRenderFns = []

export { render, staticRenderFns }